@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@600&family=Poppins:wght@800&display=swap');


body {
  background: rgb(187, 235, 241) !important;
  font-family: 'Jost', sans-serif !important;
}

h1 {
  font-family: 'Jost', sans-serif !important;
  display: flex;
  font-size: 30px;
  line-height: 100%;
  justify-content:center;
}

.button-landing {
  color: transparent !important;
  background-color: rgb(245 155 13) !important;
  border-style: none;
  width: fit-content;
  border-radius: 2px;
  padding: 3px;
}
.form-control {
  font-family: 'Poppins', sans-serif;
}

.fancy-button {
  text-decoration:none;
  font-family: 'Poppins', sans-serif;
  text-align:center;
  margin-top:5%;
  margin-left: 2.5%;
  margin-right: 2.5%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: darkslateblue;
  background: rgba(255, 217, 0);
  border: 5px solid white;
  border-radius:30px !important;
  top: 50%;
  left: 50%;
 
}

.homepage {
  display:flex;
  justify-content:center;
  flex-direction: column;
  background: linear-gradient(to right, #ba324f 0%, #ba324f 50%, white 50%, white 100%);
}

.wrap{
  /* background-color:white; */
  width: 100%;
  height: 100px;
  bottom: 0;
}

.icons {
  margin-right:.25%;
  letter-spacing: 100px;
  align-content: space-between;
}

.avatarimg {
  display:flex;
  height: 300px;
  width: 300px;
}

.skillsicons{
  display:flex;
  justify-content:space-around;
  font-size: 45px;
  color: maroon !important;
}

.homeicons{
  display:flex;
  justify-content:center;
  flex-wrap: wrap;
}